.campaign-page {
  .ant-card {
    .cart-title {
      justify-content: space-between;
      width: 100%;

      h5 {
        margin: 0;
      }
    }
  }

  .filter-section {
    margin-bottom: 16px;
  }

  .editable-cell {
    position: relative;

    .ant-form-item {
      position: relative;

      .ant-input {
        padding-right: 68px;
      }
    }

    .buttons {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .editable-cell-value-wrap {
    padding: 4px 12px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
}
