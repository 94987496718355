.column-customize-modal {
  .container-title {
    margin-bottom: 16px;
  }
}

.campaign-column-sortable-item {
  border-radius: 2px;
  padding: 6px 12px;
  border: 1px solid #d9d9d9;
  cursor: grab;
  background: white;
  outline: 0;

  & + & {
    margin-top: 6px;
  }

  &.dragging {
    opacity: 0.5;
  }
}
