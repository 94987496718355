.campaign-page {
  .ant-card {
    .cart-title {
      justify-content: space-between;
      width: 100%;

      h5 {
        margin: 0;
      }
    }
  }

  .filter-section {
    margin-bottom: 16px;
  }

  .editable-cell {
    position: relative;

    .ant-form-item {
      position: relative;

      .ant-input {
        padding-right: 68px;
      }
    }

    .buttons {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .editable-cell-value-wrap {
    padding: 4px 12px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }

  .ant-table {
    .row-actions {
      width: 100%;
      justify-content: space-between;

      &-text {
        .text {
          cursor: pointer;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

          &:hover {
            color: #1890ff;
          }
        }

        .ant-btn-sm {
          opacity: 0;
        }
      }
    }

    &-cell-row-hover {
      .row-actions-text {
        .ant-btn-sm {
          opacity: 1;
        }
      }
    }
  }
}
