.mediaBuyerPreform-panel {
    padding-top: 3em;
    padding-bottom: 2em;
}

.mediaBuyer-upper-banner {
    width: 100%;
    display: flex;
    padding: 1em auto;
}

.mediaBuyer-title {
    flex: 5;
    padding: 1.8em 1em;
    font-size: 18px !important;
    position: relative;
    top: 2.9em;
}

.mediaBuyer-filter-wrapper {
    flex: 1;
    padding: 2em;
    font-size: 40em;
}

.mediaBuyer-filter-section {
    padding: 2.4em 2em;
}