.totalData-panel {
    padding-top: 4em;

    .ant-card {
        .total-title {
            justify-content: space-between;
            width: 100%;

            h5 {
                margin: 0;
            }
        }
    }
}