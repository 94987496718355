.site-layout {
  min-height: 100vh;
  padding: 16px;

  &--content {
    height: 100%;
    padding: 24px;
    background: var(--font-inverted);
  }
}
