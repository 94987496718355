.site-sidebar {
  &.ant-layout-sider-collapsed {
    .logo {
      padding-left: 23px !important;
    }

    .user {
      margin-left: 26px !important;
    }
  }

  .logo {
    transition: all 0.2s;
  }

  .user {
    margin: 16px;
    color: var(--font-inverted);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s;
  }
}
