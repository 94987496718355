

.network-title {
    padding: 3.2em 1em 2.5em;
    font-size: 18px !important;
}

.row-actions {
    justify-content: space-between;
    width: 100%;
}