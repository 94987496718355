.report-page {
  .ant-card {
    .cart-title {
      justify-content: space-between;
      width: 100%;

      h5 {
        margin: 0;
      }
    }
  }

  .filter-section {
    margin-bottom: 16px;
  }
}
