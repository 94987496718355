.ant-table-resize {
  .ant-table-thead {
    .ant-table-cell {
      user-select: none;

      &:hover {
        .resize-control {
          background: #DDD;
        }
      }

      .resize-control {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        background: transparent;
        cursor: ew-resize;
        transition: .5s ease;
        z-index: 1;

        &:hover {
          width: 100%;
          background: rgba(0, 0, 0, 0.05)
        }
      }
    }
  }
}

.ant-table {
  .ant-table-body {
    .editable-cell-value-wrap {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ant-table-cell {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
  }
}
